@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@font-face {
    font-family: "Domaine Display";
    font-weight: 400;
    font-style: normal;
    src: url("../../assets/font/DomaineDispNar-RegularItalic.otf") format("otf");
}
@font-face {
    font-family: "Domaine Display";
    font-weight: 500;
    font-style: normal;
    src: url("../../assets/font/DomaineDispNar-MediumItalic.otf") format("otf");
}

@font-face {
    font-family: "Domaine Display";
    font-weight: 600;
    font-style: normal;
    src: url("../../assets/font/DomaineDispNar-SemiboldItalic.otf")
        format("otf");
}

@font-face {
    font-family: "Domaine Display";
    font-weight: 700;
    font-style: normal;
    src: url("../../assets/font/DomaineDispNar-BoldItalic.otf") format("otf");
}
@font-face {
    font-family: "Domaine Display Nar";
    font-weight: 800;
    font-style: normal;
    src: url("../../assets/font/DomaineDispNar-ExtraboldItalic.otf")
        format("otf");
}
::selection {
    background-color: #e43ade; /* Set the background color */
    color: #ffffff; /* Set the text color */
}

$primary-color: #323232;
// $background-color: #0f0f0f;
$background-color: #000;
$number-of-classes: 10; // Define the number of classes you want to generate

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    color: #fff;
}
#root {
    overflow-x: hidden;
}

body {
    background-color: $background-color;
    cursor: url("../../assets/icons/cursor.svg"), auto;
}

button {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background-color: #1e2122;
    color: #ffffff;
    font-size: 1rem;
    border: none;
    font-weight: 600;
    &:hover {
        transform: scale(1.05);
    }
}
.type-1-button {
    padding: 0.75rem 1.8rem;
    border-radius: 0.9rem;
}
.type-1-button.secondary-button {
    background-color: transparent;
    border: 2px solid #323232;
}

.uppercase {
    text-transform: uppercase;
}

.max-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}
.align-bottom {
    align-items: flex-end;
}

@for $i from 1 through $number-of-classes {
    $value: $i * 1rem;

    .gap-#{$i}rem {
        gap: $value !important;
    }

    .mt-#{$i}rem {
        margin-top: $value !important;
    }

    .mb-#{$i}rem {
        margin-bottom: $value !important;
    }

    .ml-#{$i}rem {
        margin-left: $value !important;
    }

    .mr-#{$i}rem {
        margin-right: $value !important;
    }

    .pt-#{$i}rem {
        padding-top: $value !important;
    }

    .pb-#{$i}rem {
        padding-bottom: $value !important;
    }

    .pl-#{$i}rem {
        padding-left: $value !important;
    }

    .pr-#{$i}rem {
        padding-right: $value !important;
    }

    .p-#{$i}rem {
        padding: $value !important;
    }
}

.home-page-card {
    border-radius: 34.526px;
    border: 1.2px solid #282828;
    background: #101213;
    width: fit-content;

    &.size-1-info-card {
        padding: 22px;
        // width: 576.782px;
        // border: 1.112px dashed #4d4d4d;
        background-color: unset !important;
        border: unset !important;
        // background: #02030c;
        background-image: url("../../assets/others/border.svg") !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        height: 322.917px;
    }

    &.size-3-card {
        padding: 1rem;
        width: 276.205px;
        height: 244.726px;
    }

    &.gradient-card {
        background: linear-gradient(46deg, #e71437 0%, #c357e0 100%);
    }

    &.size-2-card {
        padding: 1.2rem;
        height: 54.835px;
        width: 100%;
    }

    &.size-4-card {
        padding: 1.2rem;
        height: 244.726px;
        width: 580px;
        // width: 100%;
    }
    &.size-4-card.skills-card {
        overflow: hidden;
        position: relative;

        padding: 0 !important;
        .layer {
            position: absolute;
            width: 105%;
            height: 100%;
            top: 0;
            background: linear-gradient(
                90deg,
                #101213 4.31%,
                rgba(16, 18, 19, 0) 47.41%,
                #101213 93.56%
            );
        }
        @keyframes slide {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-100%);
            }
        }
        .square-card-footer {
            position: absolute;
            bottom: 2rem;
            width: 100%;
            padding: 0 2rem;
        }
        .skills-img {
            display: flex;
            width: 100%;
            img {
                width: 109%;
                transform: scale(1.2);
                animation: slide 70s linear infinite;
            }
        }
    }
}

.flex {
    display: flex;

    &.align-center {
        align-items: center;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-between {
        justify-content: space-between;
    }
}
.home-page-grid {
    // transform: scale(0.9);
    display: flex;
    flex-direction: column;
    gap: 1.35rem;
    margin-top: 50px;

    .home-page-grid-row {
        display: flex;
        gap: 1.35rem;

        &-col {
            display: flex;
            flex-direction: column;
            gap: 1.35rem;
            width: 100%;
        }
    }
}
.project-page-grid {
    .home-page-grid-row {
        &-col {
            width: unset;
        }
    }
}

.home-page-card,
.ProjectCard {
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 3s ease, transform 1s ease;
}

.home-page-card.animate,
.ProjectCard.animate {
    opacity: 1;
    transform: scale(1);
}

.page-wrap {
    position: relative;
    transform-origin: top;
    transform: scale(0.9);
}
.page-wrap.overflowhidden{
    overflow: hidden;
    height: 100vh;
}
.bottom-bar-absolute {
    position: fixed !important;
    bottom: 1.5rem;
    width: 100%;
}
.icon-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.icon-circle {
    .icon-circle-img {
        width: 4px;
        height: 4px;
        background-color: #fff;
        border-radius: 50%;
        margin-left: 7px;
    }
    // width: 100%;

    position: absolute;
    bottom: 4px;
    text-align: center;
}
.home-page-card {
    .animated-arrow {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #434343;
        transition: all 0.45s ease-in-out;
        img {
            width: 14px;
            transition: all 0.45s ease-in-out;
        }
    }
    .animated-arrow.gradient-animated-arrow {
        border: 1px solid rgba(255, 255, 255, 0.3);
    }
}
.home-page-card {
    &:hover {
        transform: scale(1.019);
        .animated-arrow {
            transform: scale(1.24);
            background-color: rgba(255, 255, 255, 0.04);
            img {
                transform: scale(1.1) rotate(-45deg);
            }
        }
        .animated-arrow.gradient-animated-arrow {
            background-color: rgba(255, 255, 255, 0.14);
        }
    }
}
.experience-home-card {
    &:hover {
        .animated-arrow {
            transform: scale(1);
            img {
                transform: scale(1) rotate(0);
            }
        }
    }
    .new-experience-card {
        &:hover {
            transform: scale(1.04);
            .animated-arrow {
                transform: scale(1.24);
                background-color: rgba(255, 255, 255, 0.04);
                img {
                    transform: scale(1.1) rotate(-45deg);
                }
            }
        }
    }
}
.page-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-size: 18rem;
        font-weight: 800;
        margin-bottom: 0.5rem;
        font-family: "Domaine Display Nar";
        font-style: italic;
    }
    p {
        font-size: 3rem;
        color: #cacaca;
        font-weight: 600;
        font-family: "Domaine Display Nar";
        font-style: italic;
    }
    button {
        margin-top: 4rem;
        border-radius: 9.728px;
        background: linear-gradient(102deg, #ef3e97 0%, #923df7 100%);
        padding: 10px 32px;
        padding-left: 28px;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.519px;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 11;
    overflow-x: scroll;
    padding-top: 4rem;
} 

@media (max-width: 1300px) {
    .page-wrap {
        transform: scale(0.8);
    }
}
@media (max-width: 1025px) {
    .home-page-grid {
        margin-top: 26px;
        gap: 13px;
    }
    .page-wrap {
        transform: scale(1);
    }
    .home-page-card {
        &.size-2-card {
            padding: 0.8rem;
            height: 37.835px;
            width: 100%;
        }
        &.size-1-info-card {
            padding: 2px;
            border-radius: 24px;
            background-image: url("../../assets/others/border2.svg") !important;
            width: 100%;
            height: unset;
        }
        &.size-3-card {
            border-radius: 24px;
            padding: 14px 1.4rem !important;
            width: 100%;
            height: unset;
        }
        &.size-3-card.social-card {
            padding: 10px !important;
            border-radius: 20px;
            .square-card-footer {
                width: 95%;
                margin: 0 auto;
            }
            .square-card-content {
                margin-top: 0 !important;
            }
        }
        &.size-4-card {
            border-radius: 24px;
            padding: 18px !important;
            height: unset;
            width: 100%;
        }
    }
    .home-page-grid .home-page-grid-row {
        gap: 13px;
    }
}
@media (max-width: 800px) {
    .bottom-bar-absolute {
        display: none;
    }
    .page-404 {
        margin-top: 3rem;
        h1 {
            font-size: 12rem;
            line-height: 120%;
            margin-bottom: 0rem;
        }
        p {
            line-height: 120%;
            font-size: 1.9rem;
        }
        button {
            margin-top: 3rem;
            font-size: 18px;
        }
    }
}
@media (max-width:750px) {
    .backdrop{
        padding-top: 1rem;
    }
}

.feed-back-page {
    h1 {
        font-family: Domaine Display Narrow;
        font-size: 56.392px;
        font-style: italic;
        font-weight: 700;
    }
    .feedback-cards {
        margin-top: 4rem;

        -webkit-mask-image: linear-gradient(
            90deg,
            transparent,
            #fff 190px,
            #fff calc(100% - 190px),
            transparent
        );
        mask-image: linear-gradient(
            90deg,
            transparent,
            #fff 190px,
            #fff calc(100% - 190px),
            transparent
        );
        overflow-y: hidden;
        @keyframes slide {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-100%);
            }
        }
        @keyframes slide2 {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0);
            }
        }
        .feedback-cards-list {
            display: flex;
            gap: 3rem;
            align-items: flex-start;
            animation: slide 60s linear infinite;
            .feedback-card {
                min-width: 500px;
                .feedback-card-block {
                    position: relative;
                    border-radius: 12px;
                    padding: 30px;
                    height: 320px;
                    padding-top: 40px;
                    .feed-back-logo {
                        width: 60px;
                        img {
                            width: 100%;
                        }
                    }
                    .feedback {
                        color: #fff;
                        font-size: 24.24px;
                        margin-top: 2rem;
                        margin-bottom: 1.2rem;
                        font-weight: 600;
                        line-height: 31px;
                        letter-spacing: -0.757px;
                    }
                    // &::before {
                    //     content: "";
                    //     width: 0px;
                    //     height: 0px;
                    //     position: absolute;
                    //     border-left: 10px solid #00bfb6;
                    //     border-right: 10px solid transparent;
                    //     border-top: 10px solid #00bfb6;
                    //     border-bottom: 10px solid transparent;
                    //     left: 20px;
                    //     bottom: -23px;
                    // }
                }

                .feedback-name {
                    margin-top: 1.3rem;
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    img {
                        width: 70px;
                    }
                    .feedback-name-info {
                        .feedback-name-info-name {
                            color: #fff;
                            font-size: 21.953px;
                            font-weight: 600;
                            letter-spacing: -0.519px;
                        }
                        .feedback-name-info-date {
                            color: #a0a0a0;
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: -0.36px;
                        }
                    }
                }
            }
            .feedback-card:nth-child(3n + 1) .feedback-card-block {
                background: linear-gradient(
                    46deg,
                    #df2b63 31.79%,
                    #d83669 52.32%,
                    #c356e7 100%
                );
            }

            /* Group 2 (2nd, 5th, 8th, etc.) */
            .feedback-card:nth-child(3n + 2) .feedback-card-block {
                background: linear-gradient(46deg, #cb2f9c 0%, #6f3ee9 100%);
            }

            /* Group 3 (3rd, 6th, 9th, etc.) */
            .feedback-card:nth-child(3n + 3) .feedback-card-block {
                background: linear-gradient(226deg, #cb2f9c 0%, #6f3ee9 100%);
            }
        }
        .feedback-cards-list.feedback-cards-list2 {
            margin-top: 4rem;
            animation: slide2 60s linear infinite;
        }
    }
}
// .feed-back-page.strech-navbar{
//     max-width: unset;width: 100%;
//     transform: unset;
// }

@media (max-width: 800px) {
    .feed-back-page {
        padding-bottom: 5rem;
        transform: unset;
        h1 {
            font-size: 36.392px;
            margin-left: 1rem;
        }
        .feedback-cards {
            margin-top: 3rem;

            -webkit-mask-image: unset;
            mask-image: unset;
           
            overflow-y: hidden;
            @keyframes slide {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-100%);
                }
            }
            @keyframes slide2 {
                0% {
                    transform: translateX(-100%);
                }
                100% {
                    transform: translateX(0);
                }
            }
            .feedback-cards-list {
                gap: 2rem;
                animation: slide 20s linear infinite;
                .feedback-card {
                    min-width: 300px;
                    .feedback-card-block {
                        padding: 16px;
                        padding-top: 25px;
                        height: unset;
                        .feed-back-logo {
                            width: 40px;
                        }
                        .feedback {
                            font-size: 15px;
                            margin-top: 0.7rem;
                            margin-bottom: 1rem; 
                            line-height: 19px; 
                        } 
                    }

                    .feedback-name {
                        margin-top: 0.7rem; 
                        gap: 0.6rem;
                        align-items: center;
                        img {
                            width: 40px;
                        }
                        .feedback-name-info {
                            .feedback-name-info-name { 
                                font-size: 17px; 
                                line-height: 18px; 
                            }
                            .feedback-name-info-date { 
                                font-size: 14px;
                                font-weight: 500; 
                                line-height: 16px;
                            }
                        }
                    }
                }
            }
            .feedback-cards-list.feedback-cards-list2 {
                // display: none;
                margin-top: 3rem;
                animation: slide2 20s linear infinite;
            }
        }
    }
}

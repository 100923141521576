.modal-container-gradient {
    margin-top: 4rem;
    max-width: 630px;
    margin: 0 auto;
    border-radius: 25.63px;
    background: linear-gradient(to bottom right, #111111, #5f27a4, #db39dc);
    padding: 2px;
    .modal-container {
        border-radius: 25.63px;
        background: #101213;
        padding: 54px 42px;
        padding-bottom: 36px;
        .modal-heading {
            display: flex;
            align-items: flex-end;
            position: relative;
            h1 {
                color: #fff;
                font-size: 29.488px;
                font-weight: 600;
                line-height: 39.1px;
                letter-spacing: -0.885px;
            }
            .pill {
                position: absolute;
                background: linear-gradient(102deg, #ef3e97 0%, #923df7 100%);
                border-radius: 30px;
                right: 14%;
                bottom: 8px;
                font-size: 16.13px;
                font-weight: 600;
                line-height: 6.073px;
                letter-spacing: -0.424px;
                padding: 9px 26px;
                display: block;
            }
        }
        .modal-subtitle {
            p {
                color: rgba(215, 215, 215, 0.75);
                font-size: 18px;
                font-weight: 500;
                line-height: 25.1px;
                letter-spacing: -0.54px;
                margin-top: 11px;
            }
        }
        .modal-inputs {
            margin-top: 3rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.4rem;
            .modal-input {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 9px;
                p {
                    color: #686868;
                    font-size: 17.11px;
                    font-weight: 500;
                    line-height: 17.875px;
                    letter-spacing: -0.513px;
                }
                input,
                textarea {
                    border-radius: 8.889px;
                    border: 1.6px solid #242424;
                    background: #1b1b1b;
                    padding: 14px;
                    padding-left: 20px;
                    color: #c0c0c0;
                    font-family: Poppins;
                    font-size: 17.11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 17.875px;
                    letter-spacing: -0.513px;
                    &::placeholder {
                        color: #cbcbcb;
                        font-size: 17.11px;
                        font-weight: 500;
                        line-height: 17.875px;
                        letter-spacing: -0.513px;
                    }
                    //for foused input
                    &:focus {
                        border: 1.6px solid #c8319f;
                        outline: none;
                    }
                }

                textarea {
                    height: 90px;
                    resize: none;
                }
                .avatar-selection {
                    .avatars {
                        margin-left: 10px;
                        display: flex;
                        gap: 1rem;
                        img {
                            width: 60px;
                            border-radius: 50%;
                        }
                        img.active {
                            border: 2px solid #c8319f;
                        }
                    }
                }
            }
        }
        .modal-btns {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            button {
                padding: 17px;
                border-radius: 8px;
                color: #fff;
                font-family: Poppins;
                font-size: 18.11px;
                font-style: normal;
                font-weight: 600;
                line-height: 17.875px;
                letter-spacing: -0.543px;
                background: linear-gradient(45deg, #dd1fa6 0%, #561aeb 100%);
            }
            button.secondary {
                background: transparent;
                border: 1px solid #363636;
            }
        }
    }
}
.modal-container-gradient.confirmation-modal {
    width: 500px;
}



@media (max-width:750px) {
    .modal-container-gradient {
        margin-top: 1rem;
        width: 92%;
        max-width: unset;
        // transform: scale(0.9) !important;
        margin: 0 auto;  
        .modal-container { 
            padding: 30px 24px;
            padding-bottom: 26px;
            .modal-heading {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                position: relative;
                h1 {
                    color: #fff;
                    font-size: 22.488px;
                    font-weight: 600;
                    line-height: 29.1px;
                    letter-spacing: -0.885px;
                    br{
                        display: none;
                    }
                }
                .pill {
                    position: relative; 
                    border-radius: 30px;
                    right: 0;
                    top: 9px;
                    bottom: 0;
                    font-size: 12.13px; 
                    line-height: 6.073px; 
                    padding: 7px 26px;
                    display: block;
                }
            }
            .modal-subtitle {
                p {
                    color: rgba(215, 215, 215, 0.75);
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 25.1px;
                    letter-spacing: -0.54px;
                    margin-top: 11px;
                }
            }
            .modal-inputs {
                margin-top: 1.8rem;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .modal-input {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    p {
                        color: #686868;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17.875px;
                        letter-spacing: -0.513px;
                    }
                    input,
                    textarea {
                        border-radius: 8.889px;
                        border: 1.6px solid #242424;
                        background: #1b1b1b;
                        padding: 8px;
                        padding-left: 12px;
                        color: #c0c0c0;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 17.875px;
                        letter-spacing: -0.513px;
                        &::placeholder {
                            color: #cbcbcb;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 17.875px;
                            letter-spacing: -0.513px;
                        }
                        //for foused input
                        &:focus {
                            border: 1.6px solid #c8319f;
                            outline: none;
                        }
                    }
    
                    textarea {
                        height: 90px;
                        resize: none;
                    }
                    .avatar-selection {
                        .avatars {
                            margin-left: 10px;
                            display: flex;
                            gap: 0.8rem;
                            img {
                                width: 50px;
                                border-radius: 50%;
                            }
                            img.active {
                                border: 2px solid #c8319f;
                            }
                        }
                    }
                }
            }
            .modal-btns {
                margin-top: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 7px;
                button {
                    padding: 10px;
                    border-radius: 8px;
                    color: #fff; 
                    font-size: 14px; 
                    font-weight: 600;
                    line-height: 17.875px;
                    letter-spacing: -0.543px;
                    background: linear-gradient(45deg, #dd1fa6 0%, #561aeb 100%);
                }
                button.secondary {
                    background: transparent;
                    border: 1px solid #363636;
                }
            }
        }
    }
    .modal-container-gradient.confirmation-modal {
        width: 90%;
        max-width: unset;
    }
}

@keyframes carousel {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(calc(-100% / 6 * 1));
    }
    100% {
        transform: translateX(calc(-100% / 6 * 2));
    }
}
.contact-page-wrap {
    .contact-page-header {
        h1 {
            font-family: "Domaine Display Nar";
            font-size: 45.976px;
            font-style: italic;
            font-weight: 800;
            line-height: 48.031px;
            letter-spacing: -1.379px;
        }
        p {
            color: #a5a5a5;
            margin-top: 20px;
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: -0.63px;
        }
    }
    .contact-page-flex {
        margin-top: 3rem;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .contact-page-flex-inputs-conatiner {
            width: 42%;
            position: relative;
            padding: 0.6px;

            &::before {
                content: "";
                position: absolute;
                top: -1px;
                left: -1px;
                right: -1px;
                bottom: -1px;
                border-radius: 20px;
                background: linear-gradient(
                    to bottom right,
                    #0d0d0d,
                    #111111,
                    #5f27a4,
                    #db39dc
                );
                z-index: -1;
            }
            form {
                width: 100%;
                .contact-page-flex-inputs {
                    width: 100%;
                    background-color: #000;
                    padding: 26px 35px;
                    padding-bottom: 34px;
                    border-radius: 20px;
                    .contact-page-flex-input {
                        width: 100%;
                        margin-bottom: 25px;
                        p {
                            color: #686868;
                            font-size: 16.499px;
                            font-weight: 600;
                            line-height: 15.147px;
                            letter-spacing: -0.435px;
                            margin-bottom: 10px;
                        }
                        input {
                            width: 100%;
                            border-radius: 7.532px;
                            border: 0.837px solid #242424;
                            background: #0a0a0a;
                            padding: 12px 20px;
                            color: #d4d4d4;
                            font-size: 15.499px;
                            font-weight: 600;
                            line-height: 15.147px;
                            letter-spacing: -0.435px;
                        }
                        textarea {
                            width: 100%;
                            border-radius: 7.532px;
                            border: 0.837px solid #242424;
                            background: #0a0a0a;
                            padding: 12px 20px;
                            color: #d4d4d4;
                            font-size: 15.499px;
                            font-weight: 600;
                            line-height: 15.147px;
                            letter-spacing: -0.435px;
                            resize: none;
                            height: 130px;
                        }
                    }
                    button {
                        border-radius: 9.728px;
                        background: linear-gradient(
                            102deg,
                            #ef3e97 0%,
                            #923df7 100%
                        );
                        padding: 10px 32px;
                        padding-left: 28px;
                        font-size: 17.294px;
                        font-weight: 600;
                        letter-spacing: -0.519px;
                    }
                }
            }
        }
        .contact-page-flex-qoute-container {
            width: 40%;
            .contact-page-flex-qoute {
                color: #acacac;
                font-size: 22.41px;
                font-weight: 400;
                line-height: 33.2px;
                letter-spacing: -0.672px;
                margin-bottom: 1rem;
            }
            .contact-page-flex-qoute-author {
                font-family: "Domaine Display Nar";
                font-size: 39.284px;
                font-style: italic;
                font-weight: 800;
                line-height: 45.713px;
                letter-spacing: -1.029px;
            }
            .trusting-companies {
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-top: 3rem;
                .trusting-companies-header {
                    color: #5e5e5e;
                    font-size: 14.928px;
                    font-weight: 400;
                    line-height: 20.303px;
                    letter-spacing: 1.642px;
                    margin-bottom: 2rem;
                }
                .companies-carousel {
                    width: 100%;
                    overflow: hidden;
                    -webkit-mask-image: linear-gradient(
                        90deg,
                        transparent,
                        #fff 90px,
                        #fff calc(100% - 90px),
                        transparent
                    );
                    mask-image: linear-gradient(
                        90deg,
                        transparent,
                        #fff 90px,
                        #fff calc(100% - 90px),
                        transparent
                    );
                    .companies-carousel-list {
                        display: flex;
                        width: 300%;
                        gap: 2rem;
                        overflow: hidden;
                        animation: carousel 20s linear infinite;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
.contact-footer{
    display: none;
}

@media (max-width: 1000px) {
    .contact-footer{
        display: unset;
    }
    .contact-page-wrap {
        .contact-page-header {
            h1 {
                font-size: 36px;
                font-style: italic;
                font-weight: 800;
                line-height: 48.031px;
                letter-spacing: -1.379px;
            }
            p {
                margin-top: 6px;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: -0.63px;
                br {
                    display: none;
                }
            }
        }
        .contact-page-flex {
            flex-direction: column;
            margin-top: 2rem;
            .contact-page-flex-inputs-conatiner {
                width: 100%;
                form {
                    width: 100%;
                    .contact-page-flex-inputs {
                        padding: 20px 26px;
                        padding-bottom: 28px;
                        .contact-page-flex-input {
                            margin-bottom: 20px;
                            p {
                                font-size: 15px;
                                font-weight: 500;
                                margin-bottom: 8px;
                            }
                            input {
                                padding: 7px 20px;
                                font-size: 14px;
                                font-weight: 500;
                            }
                            textarea {
                                padding: 7px 20px;
                                font-size: 14px;
                                font-weight: 500;
                                height: 100px;
                            }
                        }
                        button {
                            padding: 8px 26px;
                            padding-left: 22px;
                            font-size: 15px;
                            font-weight: 600;
                        }
                    }
                }
            }
            .contact-page-flex-qoute-container {
                width: 100%;
                margin-top: 3rem;
                .contact-page-flex-qoute {
                    font-size: 18px;
                    line-height: 24px;
                }
                .contact-page-flex-qoute-author {
                    font-size: 30px;
                    line-height: 36px;
                }
                .trusting-companies {
                    padding-bottom: 1rem;
                    .trusting-companies-header {
                        margin-bottom: 1.4rem;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

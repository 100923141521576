.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.6s ease-in-out;
    .nav-btns {
        display: flex;
        align-items: center;
        gap: 1rem; opacity: 1; 

        .secondary-button {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .secondary-button.talk-active{
            transition: all 0.6s ease-in-out;
        }
    }
}
.strech-navbar {
    max-width: 1600px;
    transform: scale(0.9);
    transform-origin: top;
    // width: 100%;
    margin: 0 auto;
    padding: 0;
}

 

@media (max-width: 1000px) {
    .navbar {
        margin-top: 26px !important;
        .navbar-logo {
            img {
                width: 130px;
            }
        }
        .type-1-button {
            padding: 0.65rem 1.5rem;
            font-size: 0.8rem;
            border-radius: 12px;
        }
    }
}

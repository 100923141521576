.home-page-card {
    p {
        color: #8c8c8c;
        font-size: 15.255px;
        font-weight: 500;
        letter-spacing: 0.428px;
        margin-top: 13px;
    }

    h1 {
        color: #fff;
        font-size: 44.082px;
        font-family: Domaine Display Narrow;
        font-style: italic;
        font-weight: 600;
        margin-top: 13px;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: 0.992px;
    }
}
.marquee{
    overflow: hidden;
}
.marquee marquee{
    // col
    position: relative;
    top: -4px;
    color: #a8a8a8;
}
.marquee marquee b{
    // col
    font-family: Domaine Display Narrow;font-style: italic;
    color: #fff;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-65.3%);
    }
}

.size-2-card {
    .marquee-content {
        overflow-x: hidden;
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 29px;
        div {
            position: absolute;
            white-space: nowrap;
            will-change: transform;
            font-size: 12px;
            animation: marquee 12s linear infinite;
            animation-delay: 1s;
            span {
                color: #7c7c7c;
                font-size: 0.8rem;
                font-weight: 600;
                letter-spacing: 0.407px;
                z-index: 2;
                position: relative;
                img {
                    display: inline-block;
                    margin: 0 5px;
                }
            }
        }
    }
}

.square-card-footer {
    width: 100%;

    &-text {
        p {
            color: rgba(255, 255, 255, 0.67);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.822px;
            margin-bottom: 0px;
        }

        h2 {
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            letter-spacing: -0.401px;
        }
    }
}

.works-header {
    font-size: 27.737px;
    font-weight: 800;
    line-height: 28.089px;
    letter-spacing: 0.832px;
    background: linear-gradient(#707070, #414141);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.my-works-image {
    width: 165px !important;
    height: 90px;
    position: relative;
    top: -6px;
}

.square-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.square-card-content {
    height: 105px;
    width: 100%;
    .services-flex {
        position: relative;
        top: -10px;
        align-items: baseline;
        justify-content: center;
        gap: 1.8rem;
        .services-flex-col {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            gap: 0.5rem;
            transition: all 0.5s ease-in-out;
            &:hover {
                transform: scale(1.2) translateY(-20px);
            }
            p {
                color: #c2c2c2;
                text-align: center;
                font-size: 0.9rem;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.42px;
            }
        }
    }
}
.square-card-content-social {
    height: 100%;
    .square-card-content-social-icons {
        height: 120%;
        border-radius: 20.839px;
        // border: 1.097px solid #242424;
        background: #131617;
        padding: 1.5rem;
        backdrop-filter: blur(127.5px);
        width: 100%;
        display: flex;
        gap: 1.2rem;
        justify-content: center;
    }
}

// .experience-cards {
//     height: 100%;
//     width: 100%;
//     .experience-card {
//         width: 30%;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         border-radius: 35.157px;
//         gap: 1.2rem;
//         height: 100%;
//         background: #131617;
//         .experience-card-header {
//             color: #fff;
//             font-size: 2.6rem;
//             font-weight: 600;
//             letter-spacing: -1.702px;
//         }
//         .experience-card-desc {
//             color: #8c8c8c;
//             text-align: center;
//             font-size: 0.9rem;
//             font-weight: 500;
//             letter-spacing: 0.402px;
//         }
//     }
// }
.experience-home-card {
    padding: 1rem !important;

    .new-experience-cards {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .new-experience-card {
            position: relative;
            transition: all 0.3s ease-in-out;
            padding: 22px;
            padding-bottom: 13px;
            width: 30%;
            border-radius: 24.912px;
            // opacity: 0.9;
            background: #171a1c;
            .line {
                position: absolute;
                height: 60%;
                width: 1.3px;
                left: -13px;
                background-color: #212121;
            }
            .new-experience-card-header {
                color: #fff;
                font-family: "Inter";
                font-size: 46px;
                font-weight: 600;
                margin-top: 14px;
                letter-spacing: -1.757px;
                line-height: 45px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                .animated-arrow {
                    width: 35px;
                    height: 35px;
                }
            }
            .new-experience-card-desc {
                color: #8c8c8c;
                font-size: 14.22px;

                line-height: 16px;
                font-weight: 500;
                letter-spacing: -0.284px;
            }
        }
    }
    .square-card-footer {
        width: 95%;
        margin: 0 auto;
        margin-top: 0 !important;
    }
}
.arrow-absolute img {
    position: absolute;
    top: 0;
}
.work-together-text-flex {
    height: 100%;
    align-items: flex-end;
    .work-together-text {
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 49.64px;
        letter-spacing: -0.837px;
        span {
            background: linear-gradient(#b907da, #ff5f54);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
.social-card {
    padding: 10px !important;
}

@media (max-width: 1025px) {
    .size-2-card {
        .marquee-content {
            overflow-x: hidden;
            position: relative;
            width: 100%;
            max-width: 100%;
            height: 29px;
            div {
                position: absolute;
                white-space: nowrap;
                will-change: transform;
                font-size: 10px;
                animation: marquee 16s linear infinite;
                animation-delay: 1s;
                span {
                    color: #7c7c7c;
                    font-size: 10px;
                    font-weight: 600;
                    letter-spacing: 0.407px;
                    z-index: 2;
                    position: relative;
                    img {
                        display: inline-block;
                        margin: 0 5px;
                    }
                }
            }
        }
    }
    .size-1-info-card {
        .home-page-card-flex {
            align-items: flex-start !important;
            .home-page-card-flex-col {
                margin: 1rem;
                margin-right: 1.4rem !important;
                width: 36%;
                img {
                    width: 100%;
                }
                h1 {
                    font-size: 32.29px;
                    font-weight: 600;
                    line-height: 28.635px;
                    letter-spacing: 0.729px;
                    margin-top: 6px;
                    margin-bottom: 7px;
                }
                p {
                    font-size: 10.466px;
                    font-weight: 500;
                    letter-spacing: 0.314px;
                }
            }
        }
    }
    .square-card-content {
        height: unset;
        width: 100%;
        text-align: center;
        padding: 0 !important;
        img {
            width: 100%;
        }
    }
    .square-card-footer {
        margin-top: 0 !important;
        .square-card-footer-text {
            h2 {
                font-size: 1rem;
                line-height: 18px;
            }
            p {
                margin-top: 0;
                font-size: 0.65rem;
            }
        }
    }
    .home-page-card.size-3-card-square {
        padding: 21px 13px !important;
        height: 200px;
    }
    .works-header {
        font-size: 23px;
    }
    .my-works-image {
        width: 90% !important;
        height: unset;
    }
    .recent-image {
        width: 70% !important;
    }
    .square-card-footer-text p {
        margin-bottom: 0;
    }

    // .experience-cards {
    //     .experience-card {
    //         width: 31%;
    //         padding: 1.4rem 1rem !important;
    //         border-radius: 26px;
    //         gap: 1rem;
    //         height: 100%;
    //         .experience-card-header {
    //             color: #fff;
    //             font-size: 24px;
    //             font-weight: 600;
    //             letter-spacing: -1.702px;
    //         }
    //         .experience-card-desc {
    //             color: #8c8c8c;
    //             text-align: center;
    //             font-size: 0.6rem;
    //             font-weight: 500;
    //             letter-spacing: 0.402px;
    //         }
    //     }
    // }
    .new-experience-cards {
        display: flex;
        width: 100%;
    }

    .square-card-content {
        height: unset;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        .services-flex {
            gap: 1rem;
            justify-content: space-between;
            width: 90%;
            margin: 0 auto;
            .services-flex-col {
                width: 33px;
                gap: 0rem;
                p {
                    font-size: 0.7rem;
                    line-height: 14px;
                    margin-top: 5px;
                    letter-spacing: 0.42px;
                }
                img {
                    width: 26px;
                    margin-bottom: 3px;
                }
            }
        }
    }
    .work-together-text-flex {
        height: 100%;
        align-items: flex-end;
        .work-together-text {
            color: #fff;
            font-size: 2rem;
            font-weight: 700;
            line-height: 36.64px;
            margin-top: 3rem;
            letter-spacing: 1.378px;
            span {
                background: linear-gradient(#b907da, #ff5f54);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    .arrow-absolute {
        img {
            width: 26px;
        }
    }
    .square-card-content-social {
        height: 100%;
        width: 100%;
        .square-card-content-social-icons {
            height: 100%;
            border-radius: 20.839px;
            border: unset;
            // background: linear-gradient(136deg, #262626 0%, #191919 100%);

            padding: 1rem 1.5rem;
            // backdrop-filter: blur(127.5px);
            width: 100%;
            display: flex;

            justify-content: space-around;
        }
    }
    .square-card-content-social-icons a {
        width: 23%;
    }
    .square-card-content-social-icons a img {
        width: 100%;
    }
}
@media (max-width: 700px) {
    .home-page-card.experience-home-card {
        padding: 12px !important;
    }
    .marquee marquee{
        // col
        font-size: 13px;
        position: relative;
        top: -4px;
    }
    .home-page-card.size-4-card.skills-card .square-card-footer {
        padding: 18px !important;bottom: 0rem;padding-bottom: 14px !important;
    }
    .experience-home-card {
        padding: 6px !important;
        .new-experience-cards {
            .new-experience-card {
                padding: 14px;
                padding-bottom: 14px;
                width: 31%;
                border-radius: 14.912px;
                .line {
                    position: absolute;
                    height: 60%;
                    width: 1.3px;
                    left: -6.5px;
                    background-color: #212121;
                }
                .new-experience-card-header {
                    font-size: 28px;
                    font-weight: 600;
                    margin-top: 14px;
                    letter-spacing: -1.757px;
                    line-height: 25px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    .animated-arrow {
                        width: 24px;
                        height: 24px;
                        img {
                            width: 8px;
                            height: 8px;
                        }
                    }
                }
                .new-experience-card-desc {
                    font-size: 12px;
                    line-height: 13px;
                    font-weight: 500;
                    letter-spacing: -0.284px;
                }
            }
        }
        .square-card-footer {
            width: 94%;
            margin: 0 auto;
            margin-top: 1rem !important;
        }
    }
}

.dev-img {
    width: 60%;
    img {
        width: 100%;
    }
}

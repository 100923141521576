.footer{
    text-align: center;
    .footer-logo{
        text-align: center;
        img{
            width: 140px;
            text-align: center;
            margin: 2.4rem auto;
        }
    }
}
.project-modal {
    max-width: unset;
    width: 100vw;
    padding: 0;

    background: unset;
    position: relative;
    height: 93vh;
    .modal-close-button {
        position: absolute;
        top: -40px;
        right: 20px;
        img {
            width: 30px;
            cursor: pointer;
        }
    }
    .modal-container {
        background-color: #191a1b;
        padding-bottom: 0;
        padding-top: 30px;
        height: 100%;
        overflow-y: scroll;
        .project-modal-container {
            max-width: 900px;
            margin: 0 auto;

            .key-commands {
                display: flex;
                gap: 1rem;
                align-items: center;
                justify-content: center;
                .key-commands-left {
                    display: flex;

                    gap: 8px;
                    p {
                        color: #969696;
                        font-size: 13.886px;
                        font-weight: 400;
                        letter-spacing: -0.278px;
                        position: relative;
                        top: 2px;
                    }
                    .key-commands-left-arrow {
                        img {
                            width: 25px;
                        }
                    }
                }
            }
            .key-commands-mobile {
                display: none;
            }
            .project-modal-profile {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .project-modal-profile-details {
                    display: flex;
                    gap: 10px;
                    .project-modal-profile-name {
                        color: #fff;
                        font-size: 20px;
                        font-weight: 600;
                        letter-spacing: -0.4px;
                        p {
                            font-size: 16px;
                            font-weight: 500;
                            color: #8e8e8e;
                        }
                    }
                    .project-modal-profile-img {
                        img {
                            width: 60px;
                        }
                    }
                }
                .project-modal-profile-like {
                    img {
                        width: 40px;
                        cursor: pointer;
                    }
                }
            }
            .project-details {
                text-align: center;
                margin-top: 2rem;
                .modal-heading {
                    display: unset;
                }
                .modal-desc-img {
                    margin-top: 2rem;
                    img {
                        width: 100%;
                    }
                }
                .modal-content {
                    margin-top: 2rem;
                    margin-bottom: 3rem;
                    p {
                        font-size: 18px;
                        font-weight: 500;
                        color: #bdbdbd;
                        text-align: left;
                    }
                }
                .project-modal-buttons {
                    display: flex;
                    gap: 1rem;
                    margin-top: 1.5rem;
                    button {
                        padding: 11px;
                        padding-left: 17px;
                        padding-right: 21px;
                        display: flex;
                        gap: 10px;
                        border-radius: 10px;
                        align-items: center;
                        background: linear-gradient(
                            102deg,
                            #ef3e97 0%,
                            #923df7 100%
                        );
                        font-size: 17.294px;
                        font-weight: 600;
                    }
                    button.secondary {
                        background: transparent;
                        color: #fff;
                        border: 1px solid #fff;
                    }
                }
            }
            .project-modal-footer {
                margin-top: 2rem;
                text-align: center;
                padding-bottom: 2rem;
                .project-modal-footer-header {
                    font-family: Domaine Display Narrow;
                    font-style: italic;
                    background: linear-gradient(#ec3e9a, #983df0);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 28px;
                    margin-bottom: 16px;
                    font-weight: 600;
                }
                .project-modal-footer-profile{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    .modal-line{
                        width: 90%;
                        height: 1px;
                        background-color: #424242;
                    }
                    .project-modal-footer-profile-img{
                        margin: 0 20px;
                        img{
                            width: 60px;
                        }
                    }
                }
                .project-modal-footer-name{
                    font-size: 20px;
                    font-weight: 600;
                    color: #fff;
                    letter-spacing: -0.4px;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .project-backdrop {
        padding-top: 4rem;
    }

    .project-modal {
        .modal-container {
            padding-bottom: 6rem;
            padding-top: 22px;
            .project-modal-container {
                .key-commands {
                    display: none;
                    gap: 0.8rem;
                    .key-commands-left {
                        gap: 8px;
                        p {
                            color: #969696;
                            font-size: 12px;
                            font-weight: 400;
                            letter-spacing: -0.278px;
                            position: relative;
                            top: 2px;
                        }
                        .key-commands-left-arrow {
                            img {
                                width: 21px;
                            }
                        }
                    }
                }
                .key-commands {
                    display: none;
                }
                .key-commands-mobile {
                    display: flex;
                    margin-bottom: 18px;
                    .keyboard {
                        position: relative;
                        top: -4px;
                    }
                }
                .project-modal-profile {
                    .project-modal-profile-details {
                        .project-modal-profile-name {
                            font-size: 16px;
                            p {
                                font-size: 14px;
                                top: -7px;
                                position: relative;
                            }
                        }
                        .project-modal-profile-img {
                            img {
                                width: 40px;
                            }
                        }
                    }
                    .project-modal-profile-like {
                        img {
                            width: 30px;
                        }
                    }
                }
                .project-details {
                    text-align: center;
                    margin-top: 1.5rem;
                    .modal-desc-img {
                        margin-top: 1.5rem;
                    }
                    .modal-content {
                        margin-top: 1.5rem;
                        margin-bottom: 3rem;
                        p {
                            font-size: 14px;
                            text-align: left;
                        }
                    }
                    .project-modal-buttons {
                        display: flex;
                        gap: 1rem;
                        margin-top: 1.5rem;
                        button {
                            padding: 8px;
                            padding-left: 13px;
                            padding-right: 17px;
                            gap: 7px;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.ProjectCard {
    border-radius: 28.073px;
    transition: all 0.3s ease-in-out;
    padding: 17px 14px;
    border: 1px solid #282828;
    background: #101213;
    width: fit-content;
    .ProjectCardImage {
        min-height: 240px;
        img {
            width: 335px;
            margin-bottom: 15px;
            border-radius: 28px;
        }
    }
    .ProjectCardContentFlex {
        align-items: center;
        margin-bottom: 10px;
        .animated-arrow {
            align-items: center;
            border: 1px solid #434343;
            border-radius: 50%;
            display: flex;
            width: 40px;
            height: 40px;
            text-align: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            img{
                transition: all 0.3s ease-in-out;
            }
        }
        .ProjectCardContent {
            .ProjectCardContent-desc {
                color: #6b6b6b;
                font-size: 14.83px;
                font-weight: 500;
            }
            .ProjectCardContent-name {
                color: #fff;
                font-size: 22.233px;
                font-weight: 600;
            }
        }
    }
}
.ProjectCard:hover {
    border: 1px solid #fff;
    transform: scale(1.03) ;
    background: #17191a;
     .ProjectCardContentFlex{
        .animated-arrow{
            border: 1px solid #dddddd;
            width: 50px;
            height: 50px;
            img{
                transform: scale(1.02) rotate(-45deg);
            }
        }
     }
}
.projectText {
    width: 90%;
    margin: 0 auto;
}
.project-page-grid {
    // transform: scale(0.9);
    position: relative;
    margin-top: 60px;
}
.filters {
    display: flex;
    align-items: center;
    gap: 0px;
    margin-bottom: 28px;
    .filter {
        color: #fff;
        font-size: 15.918px;
        font-weight: 600;
        padding: 12px 30px;
        border-radius: 15px;
        cursor: pointer;
    }
}
.active {
    background: #323232;
}

.relative {
    position: relative;
}

.rounded-full {
    border-radius: 9999px;
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.pill-container {
    gap: 1rem;
}
.pill-animation {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #2d2e37;
    padding: 6px 24px;
    cursor: pointer;
    span {
        display: block;
    }
}

.outline-sky-400 {
    outline-color: #93c5fd;
}

.transition {
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.focus-visible\:outline-2:focus {
    outline-width: 2px;
    outline-offset: 2px;
    outline-style: dotted;
}

.hover\:text-white\/60:hover {
    --tw-text-opacity: 0.6;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.WebkitTapHighlightColor {
    -webkit-tap-highlight-color: transparent;
}

.absolute {
    position: absolute;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.z-10 {
    z-index: 10;
}

.bg-white {
    background-color: #fff;
    color: #000;
    font-weight: 500 !important;
    cursor: pointer;
}

.mix-blend-difference {
    mix-blend-mode: difference;
}

@media (max-width: 1024px) {
    .ProjectCard {
        border-radius: 28.073px;
        padding: 17px 14px;
        width: 100%;
        margin: 0 auto;
        .ProjectCardImage {
            min-height: 260px;
            img {
                width: 100%;
                margin-bottom: 15px;
                border-radius: 28px;
            }
        }
        .ProjectCardContentFlex {
            align-items: center;
            margin-bottom: 10px;
           
            .ProjectCardContent {
                .ProjectCardContent-desc {
                    color: #6b6b6b;
                    font-size: 10.83px;
                    font-weight: 500;
                }
                .ProjectCardContent-name {
                    color: #fff;
                    font-size: 18.233px;
                    font-weight: 600;
                }
            }
        }
    }
}

.experience-page {
    width: 100%;
    .experience-page-header {
        h1 {
            font-size: 64.012px;
            font-family: "Domaine Display Nar";
            font-style: italic;
            font-weight: 800;
            line-height: 57.125px;
            letter-spacing: -0.99px;
            background: linear-gradient(0deg, #ec3e9a, #983df0);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 4rem;
        }
    }
    .experiences {
        width: 100%;
        .experience {
            position: relative;
            border-left: 1px solid #b7b7b7;
            padding-left: 30px;
            margin-bottom: 80px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            .circle {
                position: absolute;
                width: 14px;
                height: 14px;
                background-color: #d9d9d9;
                border-radius: 50%;
                left: -7px;
            }
            .experience-info {
                width: 40%;
                .experience-role {
                    color: #fff;
                    font-size: 32.914px;
                    font-weight: 600;
                    line-height: 43.657px;
                    letter-spacing: -0.658px;
                }
                .experience-company {
                    color: #fff;
                    opacity: 0.8;
                    font-size: 28.928px;
                    font-family: Domaine Display Narrow;
                    font-style: italic;
                    font-weight: 600;
                    margin-top: 5px;
                    line-height: 43.657px;
                    letter-spacing: -0.719px;
                }
                .experience-duration {
                    background: linear-gradient(#ec3e9a, #983df0);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 25.387px;
                    font-weight: 500;
                    line-height: 30.848px;
                    letter-spacing: -0.762px;
                    margin-top: 5px;
                }
            }
            .experience-desc-wrap {
                width: 55%;
                .experience-desc {
                    color: #b3b3b3;
                    // opacity: 0.7;
                    text-overflow: ellipsis;
                    font-size: 21px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 133%;
                    letter-spacing: -0.42px;
                   a{
                    color: #ec3e9a;
                   }
                }

                .view-more-button {
                    margin-top: 2rem;
                    color: #fff;
                    font-size: 21.728px;
                    font-family: Domaine Display Narrow;
                    font-style: italic;
                    font-weight: 600;
                    line-height: 133%;
                    letter-spacing: -0.435px;
                    padding: 5px 20px;
                    background-color: transparent;
                    border-radius: 8.571px;
                    border: 1.429px solid #b7b7b7;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .experience-page {
        width: 95%;
        margin-top: 1.3rem !important;
        .experience-page-header {
            h1 {
                font-size: 36px;
                line-height: 57.125px;
                letter-spacing: -0.99px;
                margin-bottom: 2rem;
            }
        }
        .experiences {
            width: 100%;
            .experience {
                flex-direction: column;
                .experience-info {
                    position: relative;
                    top: -8px;
                    width: 100%;
                    .experience-role {
                        font-size: 24px;
                        line-height: 31.833px;
                        letter-spacing: -0.48px;
                    }
                    .experience-company {
                        font-size: 22px;
                        line-height: 27.833px;
                        letter-spacing: -0.48px;
                        margin-top: 0;
                    }
                    .experience-duration {
                        font-size: 18px;
                        line-height: 21.875px;
                        letter-spacing: -0.54px;
                    }
                }
                .experience-desc-wrap {
                    width: 90%;
                    margin-left: 1.7rem;
                    margin-top: 0.8rem;
                    .experience-desc {
                        font-size: 16px;
                        line-height: 123%;
                        letter-spacing: -0.36px;
                    }
                    .view-more-button {
                        font-size: 18.728px;
                        line-height: 133%;
                        letter-spacing: -0.435px;
                        padding: 5px 20px;
                        margin-top: 1.3rem;
                    }
                }
            }
        }
    }
}
